//common scss
@use "../../assets/styles/Common/pageheader.scss";
@use "../../assets/styles/Common/table.scss";

@import "../../assets/styles/abstract/mixins";
@import "../../assets/styles/abstract/variables";

.livegroup_table_container tr td:nth-child(1),
.livegroup_table_container tr th:nth-child(1) {
  word-wrap: break-word;
  width: 25%;
}
.livegroup_table_container tr td:nth-child(2),
.livegroup_table_container tr th:nth-child(2) {
  word-wrap: break-word;
  width: 25%;
}
.livegroup_table_container tr td:nth-child(3),
.livegroup_table_container tr th:nth-child(3) {
  word-wrap: break-word;
  width: 25%;
}
.livegroup_table_container tr td:nth-child(4),
.livegroup_table_container tr th:nth-child(4) {
  word-wrap: normal;
  width: 100%;
}

.livegroup_table_container.liveusers tr td:nth-child(1),
.livegroup_table_container.liveusers tr th:nth-child(1) {
  word-wrap: break-word;
  width: 16.66%;
}
.livegroup_table_container.liveusers tr td:nth-child(2),
.livegroup_table_container.liveusers tr th:nth-child(2) {
  word-wrap: break-word;
  width: 16.66%;
}
.livegroup_table_container.liveusers tr td:nth-child(3),
.livegroup_table_container.liveusers tr th:nth-child(3) {
  word-wrap: break-word;
  width: 16.66%;
}
.livegroup_table_container.liveusers tr td:nth-child(4),
.livegroup_table_container.liveusers tr th:nth-child(4) {
  word-wrap: normal;
  width: 16.66%;
}

.livegroup_table_container.liveusers tr td:nth-child(5),
.livegroup_table_container.liveusers tr th:nth-child(5) {
  word-wrap: normal;
  width: 21.66%;
}

.livegroup_table_container .edit_btn {
  margin-right: 10px;
}


.livegroup_table_container.liveusers .actions .edit_btn{
  display: none;
}

.page_header a.live-users{
  font-size: 1rem;
  margin-right: 10px;
  padding: 0.4em 1.1em;
  border-radius: 4px;
  text-align: center;
  font-weight: 600;
  border: 1px solid $primary-color;
  color: $primary-color;
  background-color: #fff;
  transition: 0.3s ease-in-out;
}
.page_header a.live-users:hover{
  background-color: $primary-color;
  color: #fff;
}