@import "../../assets/styles/abstract/mixins";
@import "../../assets/styles/abstract/variables";

.payment-modal-box {
  --bs-modal-width: 800px !important;


  .modal-body {
    position: relative;
    padding: 1.5rem 0.4rem 1.5rem 1.2em;
  }

  form {
    min-height: fit-content;
    max-height: 80vh;
  }

  .form_inputs {
    height: 68vh;
    overflow: auto;
    padding-right: 0.8em;
    // border: 1px solid red;

    &::-webkit-scrollbar {
      position: absolute;
      right: 0em;
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.1);
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.2);
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba($primary-color, 0.4);
    }
    &::-webkit-scrollbar-thumb:active {
      background: rgba($primary-color, 0.4);
    }
  }

  .button_wrapper {
    margin-top: 2em;
    padding-right: 0.8em;

    // position: absolute;
    // bottom: 1.5rem;
    // right: 2rem;
    width: 100%;
    @include flex-box-generic(flex-end, center, row);
    gap: 1em;
    .btn_yes {
      width: 50%;
    }
    .btn_no {
      width: 50%;
    }
  }

  .user_brief {
    display: flex;
    align-items: center;
    gap: 1em;
    padding-bottom: 2em;

    .user_img {
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 1px solid #c9c9c9;
      }
    }

    .user_data {
      display: flex;
      flex-direction: column;

      p {
        margin: 0;
      }

      .name {
        font-size: 14px;
        font-weight: bold;
      }

      .email {
        color: rgba($disabled-color, 0.7);
        font-size: 12px;
      }
    }
  }

  .select_sub {
    margin-top: 2em;
    margin-bottom: 0em;
  }

  .subscription {
    @include flex-box-generic(space-between, center, row);
    border-bottom: 1px solid rgba($disabled-color, 0.5);

    padding: 0.9em 0.5em;
    .title {
      width: 50%;
      @include flex-box-generic(flex-start, center, row);
      padding: 0.25em;

      input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        border-radius: 50%;
        width: 16px;
        height: 16px;
        display: block;
        flex-shrink: 0;

        border: 2px solid #999;
        transition: 0.2s all linear;
        cursor: pointer;
      }

      input:checked {
        border: 5px solid rgba(0, 52, 148, 0.729);
        outline: unset !important;
      }

      label {
        @include flex-box-generic(flex-start, center, row);
        gap: 0.6em;
      }

      .discount_tag {
        height: 23px;
        width: 60px;
        font-size: 14px !important;
        font-weight: bold;
      }
    }
  }

  .test-series {
    .title {
      width: 70%;
    }
  }

  .no_subs {
    height: 10em;
    border-radius: 4px;
    margin: 1em 0;
    background-color: #f3f3f3;
    @include flex-box-generic(center, center, row);
  }

  .total {
    margin-top: 1em;
    width: 100%;
    @include flex-box-generic(flex-end, center, row);
    font-size: 11px;
  }

  .invoice {
    input {
      width: 100%;
      height: 35px;
      outline: none;
      padding: 0.5em;
      border: 1px solid rgba($primary-color, 0.5);
      border-radius: 4px;

      &:focus {
        border: 2px solid rgba($primary-color, 0.5);
      }
    }
    margin-bottom: 1em;
  }

  .old_price {
    color: rgba(rgb(202, 0, 0), 0.7);
    text-decoration: line-through;
  }

  .error {
    color: $danger-color;
  }

  .tabs {
    display: flex;
    gap: 2.5em;
    border-bottom: 1px solid $disabled-light;

    .tab {
      padding: 0.5em 0;
      font-size: 14px;
      color: rgba($disabled-color, 0.7);
      cursor: pointer;

      &.activeTab {
        font-weight: bold;
        font-size: 14px;
        color: rgba($primary-color, 0.7);
        border-bottom: 3px solid rgba($primary-color, 0.7);
      }
    }
  }

  .panel {
    padding: 2em 0 1em 0;
  }
}

select#zoomClass,
select#test {
  width: 100%;
  box-shadow: none;
  min-height: 15px;
  height: 33px;
  padding: 0 8px;
  background-color: transparent;
  border: 1px solid #999;
  border-radius: 8px;
}

select#zoomClass option,
select#test option {
  background-color: #f3f3f3;
  padding: 8px;
  color: #999;
}
// const customStyles = {
//   control: (provided, state) => ({
//     ...provided,
//     boxShadow: "none",
//     minHeight: "15px",
//     height: "33px",
//     display: "flex",
//     justifyContent: "flex-start",
//     alignItems: "flex-start",
//     flexDirection: "row",
//     padding: "0px",
//     border: state.isFocused ? "1px solid #bbb" : "1px solid #bbb",
//     "&:hover": {
//       border: "1px solid #555"
//     }
//   }),
//   valueContainer: (provided, state) => ({
//     ...provided,
//     height: "31px",
//     margin: "0px",
//     padding: "0 8px"
//   }),
//   input: (provided, state) => ({
//     ...provided,
//     margin: "0px"
//   }),
//   indicatorSeparator: state => ({
//     display: "none"
//   }),
//   indicatorsContainer: (provided, state) => ({
//     ...provided,
//     height: "31px"
//   })
// };


.unsubscribe.modal_container{
  width: fit-content;
}

.unsubs-container{
  display: flex;
  flex-direction: column;
  padding: 10px 32px;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;

  div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    h1{
      font-size: 18px;
      margin-bottom: 16px;
    }

    p{
      line-height: 0;
    }
  }

  .button-wrapper{
    flex-direction: row;
  }
}