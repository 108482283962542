@import "../../../assets//styles/abstract/mixins";
@import "../../../assets/styles/abstract/variables";

  .progress_bar{
    display: flex;
    flex-direction: column;
    gap: 0.4rem;

    .legend_container{
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0px;
      gap: 0.3rem;

      span{
        font-size: smaller;
      }
    }

  }
  