@use "../../assets/styles/abstract/variables" as *;
@use "../../assets/styles/abstract/mixins" as *;

.navbar_container {
  @include flex-box-generic(space-between, center, row);
  height: 54px;
  width: 100%;
  background-color: white;
  padding: 0px 20px;
  border-top-left-radius: 1vw;
  border-top-right-radius: 1vw;
  border-bottom: 2px solid $background-color;

  i {
    color: $primary_color;
  }
}
.menu_container {
  display: flex;
  position: relative;
  z-index: 4;
}
.navbar_pic_container {
  border: 1px solid grey;
  border-radius: 24px;
  height: 32px;
  width: 32px;
  object-fit: cover;
  object-position: center;
  @include flex-box-generic(center, center, row);
  overflow: hidden;
  img {
    height: 100%;
    width: 100%;
  }
}

#arrow_pic {
  padding-top: 6px;
  margin-left: 10px;
  margin-top: 3px;
}

.menu_container {
  cursor: pointer;
  user-select: none;
}

.menu_container .dropdown_menu {
  position: absolute;
  z-index: 3;
  background-color: white;
  width: 140px;
  top: 36px;
  right: 0px;
  padding: 6px !important;
  border: 1px solid grey;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;

  .dropIcon {
    margin-right: 0.3em;
  }
}

.dropdown_divider {
  width: 100%;
  padding: 0px;
  &:hover {
    background-color: white;
  }
  hr {
    margin: 0.5rem;
  }
}

.navbar_container a {
  display: flex;
  align-items: center;
  margin: 0px;
  list-style: none;
  padding: 10px 8px 10px 18px;
  cursor: pointer;
  text-decoration: none;
  color: unset;

  &:hover {
    background-color: rgb($primary_color, 0.1);
    color: $primary_color;
  }

  li {
    margin-left: 0.4rem;
    // color: $secondary-color;
  }

  @media screen and (max-width: 428px) {
    padding: 8px 14px;
  }
}

.row-container {
  @include flex-box-generic(center, center, row);
  .upload_container {
    margin-right: 20px;
    border: 1px solid $primary-color;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    @include flex-box-generic(center, center, row);
    position: relative;
    cursor: pointer;

    .upload_indicator {
      width: 100%;
      height: 100%;
      text-align: center;
      @include flex-box-generic(center, center, row);

      .progress_number {
        cursor: pointer;
        margin: 0;
        font-size: 9px;
      }

      .uploadCon {
        color: $primary-color;
      }
    }

    .upload_modal {
      position: absolute;
      top: 130%;
      right: 0;
      width: 500px;
      min-height: 100px;
      background-color: white;
      border-radius: 8px;
      padding: 10px;
      z-index: 2;
      display: none;
      border: 1px solid $background-color;
      box-shadow: 0 10px 8px 0 rgba(0, 0, 0, 0.1);
      @include flex-box-generic(flex-start, flex-start, column);

      // animation: slide 0.3s ease-in-out forwards;
      // transition: all 0.6s ease-in-out;

      .completed {
        opacity: 0.6;
      }
      .upload_item {
        @include flex-box-generic(flex-start, flex-start, row);
        width: 100%;
        padding: 15px;
        border-radius: 14px;

        &:hover {
          background-color: rgb($primary_color, 0.1);
          cursor: default;
        }

        .upload_icon,
        .upload_icon_progress {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: $primary_color;
          @include flex-box-generic(center, center, row);

          .icon {
            color: white;
            font-size: 14px;
          }
        }

        .upload_content {
          margin: 0 0 0 20px;
          flex: 1;
          @include flex-box-generic(flex-start, flex-start, column);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          .upload_title {
            font-weight: 400;
            margin-bottom: 8px;
            cursor: pointer;
          }

          .progress_bar {
            width: 100%;
            height: 14px;
            background-color: $background-color;
            border-radius: 8px;
            position: relative;

            .progress {
              height: 100%;
              background-color: $primary_color;
              border-radius: 8px;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
          .upload_status,
          .waiting {
            margin-top: 2px;
          }
          .waiting {
            opacity: 0.6;
          }
        }
      }

      @keyframes slide {
        0% {
          transform: translateY(-30px);
          opacity: 0;
        }
        100% {
          transform: translateY(0px);
          opacity: 1;
        }
      }

      .empty_message {
        width: 100%;
        height: 90px;
        @include flex-box-generic(center, center, column);

        p {
          opacity: 0.6;
          margin-top: 10px;

          span {
            margin-left: 4px;
          }
        }
      }
    }
  }
  .greeting_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    padding: 4px;
    h6 {
      margin-bottom: 0;
      font-weight: 500;
    }
  }
  @media screen and (max-width: 592px) {
    margin-left: auto;
  }
}

.timeContainer {
  @include flex-box-generic(center, center, row);
  width: fit-content;
  & span {
    font-weight: 500;
  }
  .time {
    width: 62px;
    font-weight: 400;

    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
  }
  @media screen and (max-width: 592px) {
    display: none;
  }
}
