@import "../../../../assets/styles/abstract/mixins";
@import "../../../../assets/styles/abstract/variables";

.card_container {
  &.selected {
    outline: 2px solid rgba($primary-color, $alpha: 0.4);
  }
  min-height: 105px;
  width: 100%;
  @include flex-box-generic(space-between, center, row);
  gap: 0.5em;
  border-radius: 10px;
  padding: 0px 5px 0px 10px;
  background-color: rgb(242, 242, 242);

  .content_thumbnail {
    width: 100px;
    height: 72px;

    border-radius: 5px;
    @include flex-center-column;
    img {
      border-radius: 5px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .master_content {
    width: 90%;
    height: 80%;
    @include flex-box-generic(space-between, center, row);
    gap: 0.5em;
    .content_details {
      width: 80%;
      height: 100%;
      margin-left: 0.5em;
      @include flex-box-generic(center, flex-start, column);
      gap: 0.75em;
      .content_title {
        h6 {
          margin: 0;
          font-size: 12px;
          font-weight: 600;
        }
      }

      .content_description {
        p {
          margin: 0;
          line-height: 150%;
          font-size: small;
          font-size: 10px;
          @include line-clamp(2);
        }
      }
    }

    .content_actions {
      width: 20%;
      height: 80%;
      // margin-right: 2%;
      @include flex-box-generic(space-between, center, row);

      .row_action {
        @include flex-box-generic(space-between, center, row);
        gap: 0.5em;
        i {
          margin-left: 2%;
          font-size: 16px;
          color: rgb(91, 86, 86);
          @include flex-box-generic(center, center, row);
          width: 25px;
          height: 25px;
          border-radius: 5px;
          background-color: rgb(255, 255, 255);

          &:hover {
            transform: scale(1.1);
            cursor: pointer;
          }

          .edit {
            color: rgba($primary-color, 0.8);
          }

          .trash {
            color: $danger-color;
          }

          .edit,
          .trash {
            height: 14px;
            width: 14px;
          }
        }
      }
    }
  }
}
