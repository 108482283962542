@use "../../assets/styles/abstract/variables" as *;
@use "../../assets/styles/abstract/mixins" as *;

.preview_body {
  height: 75vh;
  overflow-y: auto;
}

.preview_body::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.preview_body::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}
.preview_body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}
.preview_body::-webkit-scrollbar-thumb:hover {
  background: rgba($primary-color, 0.4);
}
.preview_body::-webkit-scrollbar-thumb:active {
  background: rgba($primary-color, 0.4);
}
