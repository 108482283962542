@import "../../../../assets/styles/abstract/mixins";
@import "../../../../assets/styles/abstract/variables";

.header {
  h5 {
    margin: 0;
  }
  margin: 0 0.45em;
  @include flex-box-generic(space-between, center, row);

  .actions {
    @include flex-box-generic(flex-end, center, row);

    width: 50%;

    i {
      font-size: 1.5em;
      margin-right: 1.5rem;
      &:hover {
        color: $primary-color;
        cursor: pointer;
      }

      &.tick {
        font-size: 2em;
      }
    }

    Button {
      width: 45%;
    }
  }

  margin-bottom: 1em;
}

.content {
  width: 100%;
  height: 100%;
  border: 1px solid rgb(196, 196, 196);
  padding-left: 0.5em;
  padding-right: 0.5em;
  @include flex-box-generic(space-between, center, row);
  margin: 1rem 0;

  .content_thumbnail {
    @include flex-box-generic(center, center, row);

    i {
      font-size: 3em;
    }
    position: relative;

    overflow: hidden;
    width: 125px;
    height: 70%;
    max-width: 200px;
    border: 1px solid grey;
    border-radius: 5px;
    @include flex-center-column;
    img {
      max-width: 100%;
      object-fit: cover;
    }

    .overlay {
      position: absolute;
      color: white;
      text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
      opacity: 0.95;
      margin-top: 5px;
    }
  }

  .content_about {
    max-width: 80%;
    height: 100%;
    // margin-left: 2.5rem;
    margin-left: 1.5rem;
    @include flex-box-generic(center, flex-start, column);
    gap: 0.75em;
    .content_title {
      h6 {
        margin: 0;
        font-size: 13px;
        font-weight: 600;
        color: rgba(black, 0.8);
      }
    }

    .content_description {
      p {
        margin: 0;
        line-height: 150%;
        font-size: 12px;
        @include line-clamp(2);
      }
    }
  }

  .content_actions {
    height: 70%;
    margin-right: 3%;
    @include flex-box-generic(space-between, center, column);

    gap: 1em;
    i {
      margin-left: 2%;
      font-size: 1.2em;
      color: rgb(91, 86, 86);
      @include flex-box-generic(center, center, row);
      width: 27px;
      height: 27px;
      border-radius: 5px;
      background-color: rgb(228, 244, 251);

      &:hover {
        transform: scale(1.1);
        cursor: pointer;
      }

      .edit {
        color: rgba($primary-color, 0.8);
      }

      .trash {
        color: $danger-color;
      }

      .edit,
      .trash {
        height: 15px;
        width: 15px;
      }
    }
  }
}

.draggableList {
  padding: 7px;
  width: 100%;
  height: 90%;
  overflow-y: scroll;

  &.ordering {
    background: rgb(214, 232, 243);
  }
}

.draggableList::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.draggableList::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}
.draggableList::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}
.draggableList::-webkit-scrollbar-thumb:hover {
  background: rgba($primary-color, 0.4);
}
.draggableList::-webkit-scrollbar-thumb:active {
  background: rgba($primary-color, 0.4);
}
