@import "../../../../../assets/styles/abstract/mixins";
@import "../../../../../assets/styles/abstract/variables";

.links-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    height: 720px;
    border-radius: 14px;
    background-color: $content-background-color;
    padding: 2rem;
    overflow: auto;

    @include flex-box-generic(start, start, column);

    &__loading {
        @include flex-box-generic(center, center, column);
        gap: 1rem;
        width: 100%;
        height: calc(100% - 100px);
    }
    &__input {
        border: 1px solid rgba($primary-text-color, 0.25);
        border-radius: 6px;
        padding: 0 1rem;
        width: 100%;
        height: 45px;

        &:placeholder {
            color: rgba($primary-text-color, 0.5);
        }
        &:focus {
            outline: none;
        }
    }

    h4 {
        color: $primary-text-color;
        margin: 2rem 0;
    }

    &__video_lists {
        @include flex-box-generic(start, start, row);
        flex-wrap: wrap;
        gap: 1.75rem;
        width: 100%;
        height: calc(100% - 150px);
        overflow: auto;
        border-radius: 6px;

        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: thin;  /* Firefox */
        scrollbar-color: #aeaeae #f7f7f7;  /* Firefox */
        
        &::-webkit-scrollbar-track {
            border-radius: 10px;
        }


        &__video_container {
            @include flex-box-generic(start, start, column);
            gap: 0.25rem;
            width: 248px;
            border-radius: 6px;

            video {
                width: 248px;
                height: auto;
                aspect-ratio: 16/9;
                object-fit: contain;
                border-radius: 6px;
            }
            &__description {
                width: 238px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                p {
                    cursor: pointer;
                    margin: 5px 0 0 0;
                    color: #000;
                }
                .link {
                    margin: 0;
                    color: $primary-color;
                    font-weight: 300;
                    font-size: 0.75rem;
                    font-style: italic;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    &__table_pagination {
        @include flex-box-generic(end, center, row);
        gap: 1rem;
        width: 100%;
        margin-top: 0.75rem;
        button {
            border: none;
            background-color: transparent;
            cursor: pointer;
            outline: none;
            padding: 0.5rem;
            border-radius: 6px;
            transition: all 0.2s ease-in-out;

            &:disabled {
                cursor: not-allowed;
                opacity: 0.5;
            }
        
            &:hover {
                background-color: rgba($primary-text-color, 0.1);
            }
        }
        span {
            color: $primary-text-color;

            input {
                border: none;
                background-color: transparent;
                outline: none;
                width: 30px;
                text-align: center;
                color: $primary-color;
            }
        }
    }

    &__no-data {
        @include flex-box-generic(center, center, column);
        width: 100%;
        height: calc(100% - 100px);

        h4 {
            color: $secondary-text-color;
            margin: 1rem 0 0 0;
        }
        p {
            color: $primary-text-color;
            margin: 0;
            font-weight: 300;
        }
    }
}