.liveDetail{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 40px;

    h1,
    h1.not-found{
        color: #4D4D4D;
        font-family: "Poppins",sans-serif;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: -0.28px;
    }

    .list{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;

        p{
            color: #4D4D4D;
            font-family: "Poppins", sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            letter-spacing: -0.16px;
        }

        .item{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            border-radius: 12px;
            background: #ededed;
            padding: 24px;

            div{
                width: 55%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 12px;

                p{
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                    align-items: flex-start;
                }
            }

            div.last{
                width: 45%;
                justify-content: flex-end;

                button{
                    width: 100%;
                    max-width: 204px;
                    display: inline-flex;
                    padding: 12px 15px;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    border-radius: 8px;
                    border: 1px solid #FF2828;

                    color: #FF2828;
                    font-family: "Poppins",sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 122%;
                    letter-spacing: -0.16px;
                    transition: 0.3s ease-out;

                    &:hover{
                        background-color: #FF2828;
                        color: #ededed;
                    }

                    svg{
                        width: 16px;
                        height: 16px;
                        animation: rotate 1s ease-out infinite;
                    }
                }
            }

            @media screen and (max-width:880px) {
                flex-direction: column;

                div{
                    width: 100%;

                    @media screen and (max-width:590px) {
                        flex-direction: column;
                    }
                }

                div.last{
                    width: 100%;
                    justify-content: center;
                }
            }
        }
    }
}

h1.not-found{
    color: #4D4D4D;
    font-family: "Poppins",sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.28px;
}

@keyframes rotate {
    0%{
        rotate: 0deg;
    }
    100%{
        rotate: 360deg;
    }
}