//common scss
@use "../../assets/styles/Common/pageheader.scss";
@use "../../assets/styles/Common/table.scss";

@import "../../assets/styles/abstract/mixins";
@import "../../assets/styles/abstract/variables";

.content_filter {
  width: 35%;
  @include flex-box-generic(flex-end, center, row);
  gap: 1em;

  .course_filter {
    width: 50%;
  }
  .subject_filter {
    width: 50%;
  }
}

.unit_table {
  margin-bottom: 5vh;

  h3 {
    margin-bottom: 0;
  }
  @include flex-box-generic(center, center, row);
  .button_wrapper {
    @include flex-box-generic(flex-end, center, row);
    width: 100%;

    .add_unit_button {
      width: 10vw;
    }
  }
}

.unit_table_container tr td:nth-child(1),
.unit_table_container tr th:nth-child(1) {
  word-wrap: break-word;
  width: 10%;
  min-width: 80px;
  max-width: 300px;
}
.unit_table_container tr td:nth-child(2),
.unit_table_container tr th:nth-child(2) {
  word-wrap: break-word;
  width: 40%;
  min-width: 150px;
  max-width: 700px;
}
.unit_table_container tr td:nth-child(3),
.unit_table_container tr th:nth-child(3) {
  word-wrap: normal;
  width: 20%;
  min-width: 120px;
  max-width: 700px;
}
.unit_table_container tr td:nth-child(4),
.unit_table_container tr th:nth-child(4) {
  word-wrap: normal;
  width: 20%;
  // min-width:300px;
  // max-width: 700px;
}
