@use "../../assets/styles/Common/pageheader.scss";
@use "../../assets/styles/Common/table.scss";

@import "../../assets/styles/abstract/mixins";
@import "../../assets/styles/abstract/variables";

.paymentLog_table_container {
  margin-top: 2em !important;
}

.paymentLog_table_container tr td:nth-child(1),
.paymentLog_table_container tr th:nth-child(1) {
  width: 8%;
}

.paymentLog_table_container tr td:nth-child(2),
.paymentLog_table_container tr th:nth-child(2) {
  width: 10%;
}

.paymentLog_table_container tr td:nth-child(3),
.paymentLog_table_container tr th:nth-child(3) {
  word-wrap: normal;
  word-break: normal;
  width: 7%;
}

.paymentLog_table_container tr td:nth-child(3),
.paymentLog_table_container tr th:nth-child(3) {
  word-wrap: normal;
  word-break: normal;
  width: 10%;
}

.paymentLog_table_container tr td:nth-child(4),
.paymentLog_table_container tr th:nth-child(4) {
  width: 10%;
}

.paymentLog_table_container tr td:nth-child(5),
.paymentLog_table_container tr th:nth-child(5) {
  width: 10%;
}

.paymentLog_table_container tr td:nth-child(6),
.paymentLog_table_container tr th:nth-child(6) {
  width: 12%;
}

.paymentLog_table_container tr td:nth-child(7),
.paymentLog_table_container tr th:nth-child(7) {
  width: 10%;
}

.paymentLog_table_container tr td:nth-child(8),
.paymentLog_table_container tr th:nth-child(8) {
  width: 10%;
}

.paymentLogDetails {
  min-height: 30px;
  @include flex-box-generic(flex-start, center, row);

  &:hover {
    cursor: pointer;
  }
  padding-right: 4em;
  h6 {
    font-weight: 600;
    font-size: 12px;
  }
  p {
    line-height: 1.5em;
    margin-bottom: 0;
    word-break: normal;
    font-size: 11px;
    @include line-clamp(2);
  }

  &:active {
    p {
      display: block;
      overflow: visible;
    }
  }
}

.profile_pic_container {
  i {
    font-size: 16px;
  }
}

.pay_status_tag,
.pay_merchant_tag {
  height: 30px;
  min-width: 75px;
  padding: 15px 0px;
  width: fit-content;

  display: flex;
  align-items: center;
  justify-content: center;
}

.pay_merchant_tag {
  min-width: 100px;
}

.tabs {
  display: flex;
  gap: 2.5em;
  border-bottom: 1px solid $disabled-light;

  .tab {
    padding: 0.5em 0;
    font-size: 14px;
    color: rgba($disabled-color, 0.7);
    cursor: pointer;

    &.activeTab {
      font-weight: bold;
      font-size: 14px;
      color: rgba($primary-color, 0.7);
      border-bottom: 3px solid rgba($primary-color, 0.7);
    }
  }
}

.panel {
  padding: 2em 0 1em 0;
}
