@import "../../assets/styles/abstract/mixins.scss";

.container {
  display: none;
  padding: 2em;
  .contents {
    margin-top: 5em;
    @include flex-box-generic(space-around, center, column);
    gap: 3em;
    h5 {
      color: rgba(black, 0.85);
      font-size: 1em;
    }
  }
}

.show {
  display: block;
}
