@import "../../../../assets/styles/abstract/mixins";
@import "../../../../assets/styles/abstract/variables";

.live-form-container {
  height: fit-content;
  padding: 3vw 2vw;

  max-width: 950px;
  min-width: 750px;
  border-radius: 1vh;

  .row-container {
    width: 100%;
    @include flex-box-generic(space-between, center, row);
    gap: 3em;

    .fieldAndValidate {
      width: 100%;
      position: relative;

      p {
        color: red;
        margin-top: 1vh;
        position: absolute;
      }
      & course-id {
        width: 20%;
      }

      label {
        font-size: medium;
        margin-bottom: 0.5em;

        span {
          color: $danger-color;
        }
      }
    }

    .button-wrapper {
      gap: 1vw;
      width: 100%;
      margin-top: 5em !important;
      @include flex-box-generic(flex-end, flex-end, row);

      Button {
        width: 20%;
      }
    }

    .col-container {
      gap: 5vh;
      width: 100%;
      height: 100%;
      @include flex-box-generic(flex-start, center, column);
    }

    @media screen and (max-width: 728px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center !important;
    }
  }
}
