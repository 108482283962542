@use "../../assets/styles/abstract/variables" as *;

.actions {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;

  i {
    margin-right: 1.3em;
    cursor: pointer;
    font-size: 16px;
  }
}
.edit_btn {
  height: 16px;
  width: 16px;
  color: $primary-color;
  cursor: pointer;
  margin-right: 20px;
}

.trash_btn {
  height: 16px;
  width: 16px;
  cursor: pointer;
  color: $danger-color;
}

.modal_container {
  max-height: 90vh;
  min-width: fit-content;

  .modalTitle {
    padding: 1vw 2vw;
  }

  .modalBody {
    max-height: 85vh;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    padding: 0 !important;
    padding-bottom: 1em;
  }

  .modalBody::-webkit-scrollbar {
    display: none;
  }
}

.payment_button {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    font-size: 11px !important;
  }
}
