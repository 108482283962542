@import "../../assets/styles/abstract/mixins";
@import "../../assets/styles/abstract/variables";

$label-font-size: 5rem;

.text_image_input {
  // position: relative;

  width: 100%;
  gap: 0.5rem;
  padding: 0.7em 0.7em;
  border-radius: 0.5rem;
  border: 1px solid rgba($secondary-color, 0.3);

  @include flex-box-generic(flex-start, flex-start, column);

  .text_area {
    gap: 0.5em;
    width: 100%;
    @include flex-box-generic(space-between, flex-start, row);

    textarea {
      width: 100%;
      border: none;
      outline: none;
      resize: vertical;
      border-bottom: 1px solid rgba($secondary-color, 0.2);
    }

    .option_label {
      width: 30px;
      p {
        margin: 0;
        font-size: 15px;
        font-weight: 600;
        word-break: normal;
        @include flex-box-generic(center, center, row);
      }
    }

    .upload_image {
      margin-left: 0.5em;
      i {
        font-size: x-large;
      }
    }

    .audio_input {
      margin-left: 0.5em;
      font-size: large;
    }
  }

  .imagePreview {
    width: 100%;
    margin-top: 1em;
    height: max-content;

    .image {
      width: 50%;
      position: relative;

      img {
        width: 100%;
        max-width: originalWidth;
      }

      .remove {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: white;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        @include flex-box-generic(center, center, row);

        position: absolute;
        top: -10px;
        right: -10px;

        background-color: #fff;
        text-align: center;

        i {
          font-size: large;
          font-weight: 600;
        }
      }
    }
  }

  
  .audioPreview {
    width: 100%;
    margin-top: 1em;
    height: max-content;

    .audio {
      width: 50%;
      position: relative;

      audio {
        width: 100%;
        max-width: originalWidth;
      }

      .remove {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: white;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        @include flex-box-generic(center, center, row);

        position: absolute;
        top: -10px;
        right: -10px;

        background-color: #fff;
        text-align: center;

        i {
          font-size: large;
          font-weight: 600;
        }
      }
    }
  }

}
