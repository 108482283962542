@use "../../assets/styles/abstract/variables" as *;
@use "../../assets/styles/abstract/mixins" as *;

th > span {
  margin-left: 0.5rem;
}

.table_container {
  position: relative;
  margin-top: 3em !important;
  overflow-x: scroll;
  tbody {
    margin-top: 12rem;
    td {
      line-height: 2.1 !important;
    }
    .actions {
      line-height: 2.1 !important;
    }
  }

  tbody:before {
    height: 0px !important;
  }

  th {
    font-size: 13px !important;
  }

  td {
    font-size: 13px;
  }
}

.table_container::-webkit-scrollbar {
  display: none;
}

.table_container tr {
  //animation name will be added with reactjs
  animation-duration: 1s;
  animation-direction: normal;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

@keyframes fade-out {
  0% {
    opacity: 100%;
  }
  100% {
    opacity: 0%;
  }
}

.table_pagination {
  color: $primary-color;
  right: 0;
  bottom: 5px;
  margin: 20px 0px; 
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 0.6em;
  margin-top: 20px;
 

  button {
    background-color: white;
    border-radius: 3px;
    color: $primary-color;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    width: 30px;
    outline: none;
    border: none;

    &:disabled {
      color: #c9c9c9;
    }
  }

  input {
    color: $primary-color;
    text-align: center;
  }
}

.table-row:hover {
  background-color: rgb(234, 246, 255);
}
