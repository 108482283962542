//common scss
@use "../../assets/styles/Common/pageheader.scss";
@use "../../assets/styles/Common/table.scss";

@import "../../assets/styles/abstract/mixins";
@import "../../assets/styles/abstract/variables";

.instructor_page {
  .content_filter {
    width: 15%;
    .course_filter {
      min-width: 100%;
    }
  }

  .instructor_table_container tr td:nth-child(1),
  .instructor_table_container tr th:nth-child(1) {
    word-wrap: break-word;
    width: 25%;
    min-width: 80px;
    max-width: 300px;
  }
  .instructor_table_container tr td:nth-child(2),
  .instructor_table_container tr th:nth-child(2) {
    word-wrap: break-word;
    width: 13%;
    min-width: 150px;
    max-width: 700px;
  }
  .instructor_table_container tr td:nth-child(3),
  .instructor_table_container tr th:nth-child(3) {
    word-wrap: normal;
    width: 22%;
    min-width: 120px;
    max-width: 700px;
  }
  .instructor_table_container tr td:nth-child(4),
  .instructor_table_container tr th:nth-child(4) {
    word-wrap: normal;
    width: 15%;
  }
  .instructor_table_container tr td:nth-child(5),
  .instructor_table_container tr th:nth-child(5) {
    word-wrap: normal;
    width: 15%;
  }
}
