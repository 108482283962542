@import "../../../../assets/styles/abstract/mixins";
@import "../../../../assets/styles/abstract/variables";

.coursegroup-form-container {
  height: fit-content;
  padding: 3vw 2vw;

  max-width: 950px;
  min-width: 750px;
  border-radius: 1vh;

  .row-container {
    width: 100%;
    @include flex-box-generic(space-between, center, row);

    .fieldAndValidate {
      width: 100%;
      position: relative;

      p {
        color: $danger-color;
        margin-top: 1vh;
        position: absolute;
      }
      & course-id {
        width: 20%;
      }
    }

    .group-id {
      width: 30%;

      input {
        text-transform: uppercase;
      }

      input::placeholder {
        text-transform: none;
      }
    }

    .course-name {
      width: 65%;
    }

    .course_image {
      width: 30%;
      height: 100%;
      min-width: 120px;
    }

    .button-wrapper {
      gap: 1vw;
      width: 100%;
      margin-top: 4em !important;
      @include flex-box-generic(flex-end, flex-end, row);

      Button {
        width: 20%;
      }
    }

    .col-container {
      gap: 5vh;
      width: 100%;
      height: 100%;
      @include flex-box-generic(flex-start, center, column);
    }

    @media screen and (max-width: 728px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center !important;
    }
  }
}
