.table_container {
  margin-top: 2rem;
  th {
    font-size: 1.1rem;
  }
}
.table_container tbody:before {
  content: "";
  display: block;
  height: 10px;
}

.table_container .profile_container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.table_container .profile_pic_container {
  overflow: hidden;
  border-radius: 20%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  // border: 1px solid rgb(214, 212, 212);

  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.search_wrapper {
  align-self: flex-end;
}

.table_container {
  min-height: 72vh;
  max-height: 72vh;
  tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
.table_container .actions {
  display: block;
  height: 100%;
  min-height: fit-content;
  border: none;
}
.table_container .role {
  height: 20px;
}
