$primary-color: #da1b1b;
$secondary-color: #000000;

$secondary-text-color: #1e1e1e;
$primary-text-color: #5d5d5d;
$content-background-color: white;

//change color below as per need
$layout-background-color: $secondary-color;
$background-color: #f4f4f4;
$danger-color: #c70000;
$warning-color: #d8c412;
$success-color: #17ae49;
$button-background-color: white;
$disabled-color: #474747;
$disabled-light: #c9c9c9;
