@use "../../assets/styles/abstract/mixins" as *;
@use "../../assets/styles/abstract/variables" as *;

.sidebar {
  position: fixed;
  top: 2vh;
  left: 1vw;
  height: 96vh;
  width: 15vw;
  background-color: #fff;
  border-radius: 1vw;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);

  &_logo {
    margin-top: 0.5rem;
    .nameonline {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    padding: 0 1.5vw;
    height: 80px;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;

    &_image {
      width: 110px;
      margin: 7px;
    }

    &_text {
      font-size: 22px;
      font-family: "Inter", sans-serif;
      font-variant-numeric: tabular-nums;
      color: $primary-color;
      line-height: 31.43px;
      font-weight: 800;
    }
  }

  &_menu {
    height: 70vh;
    overflow: hidden;
    &:hover {
      overflow: auto;
    }
    position: relative;
    margin-right: 5px;

    :hover {
      color: $primary-color;
    }

    &_item {
      @include flex-box-generic(flex-start, center, row);
      padding: 0.8rem 2.4rem;
      font-size: 0.9rem;
      font-weight: 500;
      color: #555555;
      transition: color 0.3s ease-in-out;

      height: 45px;

      &.active {
        color: #fff;
      }

      &_icon {
        @include flex-box-generic(center, center, row);
        margin-right: 1rem;
        width: 29px;
        min-width: 29px;
        background-color: #efefef;
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);

        height: 29px;
        border-radius: 10px;

        &.active {
          color: #fff;
          background-color: rgba($primary-color, 0.9);

          :hover {
            color: white;
          }
        }
        i {
          font-size: 1rem;
        }
        @media screen and (max-width: 900px) {
          margin-right: 0.3rem;
        }
      }

      &_text {
        &.active {
          color: rgba($primary-color, 1);
          font-weight: 900;
        }
      }
    }

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    &::-webkit-scrollbar-track {
      background: rgba($primary-color, 0.1);
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: rgba($primary-color, 0.2);
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba($primary-color, 0.4);
    }
    &::-webkit-scrollbar-thumb:active {
      background: rgba($primary-color, 0.4);
    }
  }

  &_footer {
    width: 100%;
    position: absolute;
    bottom: 1em;
    font-size: 10px;
    @include flex-center-column();

    p {
      line-height: 0.5;
      color: rgba(0, 0, 0, 0.6);

      a {
        color: $primary-color;
      }
    }
  }

  @media screen and (max-width: 794px) {
    max-width: 72px;
  }
}

//for responsive

@media screen and (max-width: 1058px) and (min-width: 794px) {
  .sidebar_menu_item {
    padding-left: 10px;
    padding-right: 0;
  }
  .sidebar_menu_item_text {
    font-size: 0.8rem;
  }
}

.sidebar_menu {
  @media screen and (max-width: 794px) {
    .sidebar_menu_item {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .sidebar_menu_item_icon {
      height: 30px;
      width: 30px;
      margin: 0;
      padding: 0;
    }
    .sidebar_menu_item_text {
      display: none;
    }
  }
}

@media screen and (max-width: 794px) {
  .sidebar_logo_image {
    width: 50px;
  }
  .sidebar_logo_text {
    display: none;
  }
  .sidebar_footer {
    display: none;
  }
}
