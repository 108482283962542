@import "../../assets/styles/abstract/mixins";
@import "../../assets/styles/abstract/variables";

.content_tab_page {
  height: 80vh;

  .content_tab_header {
    // border: 1px solid red;
    margin-bottom: 4vh;
    width: 100%;
    h {
      margin-bottom: 0;
    }
    @include flex-box-generic(space-between, center, row);
    .buttonWrapper {
      @include flex-box-generic(flex-end, center, row);
      width: 20%;

      .addCourseButton {
        width: 10vw;
      }
    }
  }

  .content-tabs {
    ul {
      border-color: $primary-color;
      li {
        width: 100px;
        text-align: center;
      }
    }

    .react-tabs__tab--selected {
      color: $primary-color;
      border-color: $primary-color;
    }

    .panel {
      // border: 1px solid red;
      width: 100%;
      margin-top: 2%;
      @include flex-box-generic(center, center, column);
    }
  }
}
