//common scss
@use "../../assets/styles/Common/pageheader.scss";
@use "../../assets/styles/Common/table.scss";

@import "../../assets/styles/abstract/mixins";
@import "../../assets/styles/abstract/variables";

.notificationTable {
  margin-bottom: 5vh;
  h3 {
    margin-bottom: 0;
  }
  @include flex-box-generic(center, center, row);
  .buttonWrapper {
    @include flex-box-generic(flex-end, center, row);

    .addCourseButton {
      width: 10vw;
    }
  }
}

.notification_table_container tr td:nth-child(1),
.notification_table_container tr th:nth-child(1) {
  word-wrap: break-word;
  width: 17%;
}
.notification_table_container tr td:nth-child(2),
.notification_table_container tr th:nth-child(2) {
  word-wrap: break-word;
  width: 40%;
}
.notification_table_container tr td:nth-child(3),
.notification_table_container tr th:nth-child(3) {
  word-wrap: break-word;
  width: 13%;
}
.notification_table_container tr td:nth-child(4),
.notification_table_container tr th:nth-child(4) {
  word-wrap: normal;
  width: 20%;
}

.notification_table_container tr td:nth-child(5),
.notification_table_container tr th:nth-child(5) {
  word-wrap: normal;
  // width: 17%;
  // min-width:300px;
  // max-width: 700px;
}

.notifDetails {
  &:hover {
    cursor: pointer;
  }
  padding-right: 3em;
  h6 {
    font-weight: 600;
    font-size: 12px;
  }
  p {
    line-height: 1.5em;
    margin-bottom: 0;
    word-break: normal;
    font-size: 11px;
    @include line-clamp(1);
  }

  &:active {
    p {
      display: block;
      overflow: visible;
    }
  }
}

.profile_pic_container {
  i {
    font-size: 16px;
  }
}
