@import "../../../../assets/styles/abstract/mixins";
@import "../../../../assets/styles/abstract/variables";

.video-content {
    width: 960px;
    height: 540px;
    overflow: hidden;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 1);
    z-index: 1000;
    border-radius: 1vh;
    margin: 0 auto;
    @include flex-box-generic(center, center, column);

    &__video {
        width: auto;
        height: 100%;
        object-fit: contain;
    }
}
.pdf-content {
    overflow: hidden;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: black;
    z-index: 1000;
    border-radius: 1vh;
    margin: 0 auto;
    @include flex-box-generic(center, center, column);

    .__pdf {
        width: 100%;
        height: 100%;
        // object-fit: contain;
    }
}