//common scss
@use "../../assets/styles/Common/pageheader.scss";
@use "../../assets/styles/Common/table.scss";

@import "../../assets/styles/abstract/mixins";
@import "../../assets/styles/abstract/variables";

.subscriptionTable {
  margin-bottom: 5vh;
  h3 {
    margin-bottom: 0;
  }
  @include flex-box-generic(center, center, row);
  .buttonWrapper {
    @include flex-box-generic(flex-end, center, row);

    .addCourseButton {
      width: 10vw;
    }
  }
}
.subscription_table_container tr th {
  padding-top: 15px;
  padding-bottom: 15px;
  white-space: nowrap;
}

.subscription_table_container tr td:nth-child(1),
.subscription_table_container tr th:nth-child(1) {
  word-wrap: break-word;
  width: 22%;
  padding-left: 20px;
  // min-width: 200px;
  // max-width: 400px;
}
.subscription_table_container tr td:nth-child(2),
.subscription_table_container tr th:nth-child(2) {
  word-wrap: break-word;
  width: 17%;
  // min-width: 170px;
  // max-width: 700px;
}
.subscription_table_container tr td:nth-child(3),
.subscription_table_container tr th:nth-child(3) {
  word-wrap: break-word;
  width: 17%;
  // min-width: 300px;
  // max-width: 630px;
}
.subscription_table_container tr td:nth-child(4),
.subscription_table_container tr th:nth-child(4) {
  word-wrap: normal;
  width: 17%;
  // min-width:300px;
  // max-width: 700px;
}

.subscription_table_container tr td:nth-child(5),
.subscription_table_container tr th:nth-child(5) {
  word-wrap: normal;
  width: 17%;
  // min-width:300px;
  // max-width: 700px;
}

.subscription_table_container tr td:nth-child(6),
.subscription_table_container tr th:nth-child(6) {
  word-wrap: normal;
  display: table-cell;
  // min-width:300px;
  // max-width: 700px;
}
