@use "../assets/styles/abstract/variables" as *;
@use "../assets/styles/abstract/mixins" as *;

.main {
  height: min-content;
  min-height: 96vh;
  max-height: 96vh;
  margin: 2vh 1vw 0px 16.5vw;
  padding: 0vh 4vh;
  border-radius: 1vw;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;

  .navbar_wrapper {
    @include flex-box-generic(flex-end, center, row);
    border-radius: 1vw;
    position: fixed;
    right: 1vw;
    width: 82.5vw;

    z-index: 2;
    @media screen and (min-width: 512px) and (max-width: 794px) {
      width: 100%;
      margin-left: auto;
      max-width: calc(100% - 90px);
    }
  }
  @media screen and (min-width: 512px) and (max-width: 794px) {
    width: 100%;
    margin-left: auto;
    max-width: calc(100% - 90px);
  }

  .pages_wrapper {
    margin-top: 80px;
  }
}
.main::-webkit-scrollbar {
  display: none;
}
