@import "../../assets/styles/abstract/mixins";
@import "../../assets/styles/abstract/variables";

.inputContainer {
  @include flex-box-generic(flex-start, center, column);
  gap: 0.5rem;
  position: relative;
  height: 100%;
  min-width: 135px;
  max-width: 145px;
  background-color: $content-background-color;

  .inputBtnWrapper {
    @include flex-center-column;
    width: 100%;
  }

  .imageContainer {
    @include flex-box-generic(center, center, column);
    width: 100%;
    height: calc(100%);
    min-height: 144px;

    overflow: hidden;
    border: 1px solid $primary-color;

    @include set-border-radius;
    & > img {
      width: 100%;
      height: 100%;
      // object-fit: cover;
      object-fit: contain;
      // object-position: top;
    }
    & > i {
      font-size: 4rem;
      color: rgba($primary-color, 0.85);
    }
  }
}
