@use "../../assets/styles/abstract/variables" as *;

.search_box {
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
  i {
    color: $primary-color;
  }
}

.search_box button {
  background-color: white;
  border: 1px solid rgba($color: $primary-color, $alpha: 0.4);
}

.search_box button:hover {
  background-color: $primary-color;
  i {
    color: white;
  }
}

.search_box button:active {
  i {
    color: $primary-color;
  }
}

.search_box input {
  height: 100%;
  padding: 0.8rem;
}

.btn {
  margin-left: 4px;
  height: 100%;
}

.form {
  position: relative;
  width: 100%;
  .form_input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid rgba($color: $primary-color, $alpha: 0.4);
    border-radius: 6px;
    font-family: inherit;
    color: black;
    outline: none;
  }

  .form_label {
    position: absolute;
    background-color: white;
    line-height: 1;
    border-radius: 8px;
    // z-index: 2;
    color: rgba(black, 0.7);
    left: 1.4rem;
    top: 0.7rem;
    padding: 0px;
    transition: top 0.5s ease-in-out;
  }
}

.search_box .form_input:focus ~ .form_label,
.form_input:not(:placeholder-shown).form_input:not(:focus) ~ .form_label {
  top: -0.6rem;
}

.form_input:focus {
  border: 1px solid rgba($color: $primary-color, $alpha: 0.8);
}
