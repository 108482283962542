@import "../../assets/styles/abstract/mixins";
@import "../../assets/styles/abstract/variables";

.premiumWithData-container {
  height: fit-content;
  padding: 3vw 2vw;

  max-width: 950px;
  border-radius: 1vh;

  .row-container {
    width: 100%;
    @include flex-box-generic(space-between, center, row);
    gap: 3em;

    .fieldAndValidate {
      width: 100%;
      position: relative;

      p {
        color: red;
        margin-top: 1vh;
        position: absolute;
      }
      & course-id {
        width: 20%;
      }

      label {
        font-size: medium;
        margin-bottom: 0.5em;

        span {
          color: $danger-color;
        }
      }
    }

    .button-wrapper {
      gap: 1vw;
      width: 100%;
      margin-top: 5em !important;
      @include flex-box-generic(flex-end, flex-end, row);

      Button {
        width: 20%;
      }
    }

    .col-container {
      gap: 5vh;
      width: 100%;
      height: 100%;
      @include flex-box-generic(flex-start, center, column);
    }

    @media screen and (max-width: 728px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center !important;
    }
  }

  .row-container.loader{
    width: 100%;
    height: 2px;
    position: relative;
    overflow: hidden;

    span{
      display: block;
      width: 25%;
      height: 100%;
      background-color: transparent;
      animation: load 1s ease-in-out infinite;
      position: absolute;
      top: 0;
      bottom: 0;
    }
  }

  .row-container.loader.load{
    background-color: #babababa;

    span{
      background-color: $primary-color;
    }
  }
}

@keyframes load {
  0%{
    transform: translateX(-100%);
  }
  50%{
    transform: translateX(250%);
  }
  100%{
    transform: translateX(400%);
  }
}

.review-container{
  padding: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  max-height: 60vh;
  overflow-y: auto;

    ul{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      padding-bottom: 20px;
      list-style-type: decimal;

      li{
        display: list-item;
      }
    }

    .button_wrapper{
      width: 100%;
      max-width: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
}

.give-access-wrapper{
  width: 100%;
  margin-bottom: 20px;

  .tabs{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 22px;

    p{
      font-size: 18px;
      font-weight: 600;
      transition: 0.3s ease-in-out;
      cursor: pointer;
      padding: 8px 10px;
    }

    p.active,
    p:hover{
      color: $primary-color;
    }
  }
}