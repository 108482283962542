//common scss
@use "../../assets/styles/Common/pageheader.scss";
@use "../../assets/styles/Common/table.scss";

@import "../../assets/styles/abstract/mixins";
@import "../../assets/styles/abstract/variables";

.courseTable {
  margin-bottom: 5vh;
  h3 {
    margin-bottom: 0;
  }
  @include flex-box-generic(center, center, row);
  .buttonWrapper {
    @include flex-box-generic(flex-end, center, row);

    .addCourseButton {
      width: 10vw;
    }
  }
}

.course_table_container tr td:nth-child(1),
.course_table_container tr th:nth-child(1) {
  word-wrap: break-word;
  width: 10%;
}
.course_table_container tr td:nth-child(2),
.course_table_container tr th:nth-child(2) {
  word-wrap: break-word;
  width: 30%;
}
.course_table_container tr td:nth-child(3),
.course_table_container tr th:nth-child(3) {
  word-wrap: break-word;
  width: 15%;
}
.course_table_container tr td:nth-child(4),
.course_table_container tr th:nth-child(4) {
  word-wrap: break-word;
  width: 35%;
}
.course_table_container tr td:nth-child(5),
.course_table_container tr th:nth-child(5) {
  word-wrap: normal;
}
