.container {
  margin: 30px 0px;
  padding: 0px;
}
.user_container {
  display: flex;
}
.user_table_container tr td:nth-child(1),
.user_table_container tr th:nth-child(1) {
  word-wrap: break-word;
  width: 5%;
  min-width: 80px;
  max-width: 300px;
}
.user_table_container tr td:nth-child(2),
.user_table_container tr th:nth-child(2) {
  word-wrap: break-word;
  width: 25%;
  min-width: 180px;
  max-width: 300px;
}
.user_table_container tr td:nth-child(3),
.user_table_container tr th:nth-child(3) {
  word-wrap: break-word;
  width: 15%;
  min-width: 150px;
  max-width: 700px;
}
.user_table_container tr td:nth-child(4),
.user_table_container tr th:nth-child(4) {
  word-wrap: normal;
  width: 17%;
  min-width: 120px;
  max-width: 700px;
}
.user_table_container tr td:nth-child(5),
.user_table_container tr th:nth-child(5) {
  word-wrap: normal;
  width: 13%;
}
.user_table_container tr td:nth-child(6),
.user_table_container tr th:nth-child(6) {
  word-wrap: normal;
  word-break: normal;
  width: 13%;
}

.status_tag,
.verify_tag {
  height: 30px;
  min-width: 55px;
  padding: 15px 0px;
  width: fit-content;

  display: flex;
  align-items: center;
  justify-content: center;
}

.verify_tag {
  min-width: 66px;
}

.user_header {
  .search_wrapper {
    margin-right: 1em !important;
  }

  .content_filter {
    width: 15%;
    .course_filter {
      min-width: 100%;
    }
  }
}
