@import "../../../../assets/styles/abstract/mixins";
@import "../../../../assets/styles/abstract/variables";

.chapter-form-container {
  width: 60vw;
  max-width: 950px;
  height: fit-content;
  padding: 2vw 2vw !important;
  background-color: white;
  border-radius: 1vh;
  @include flex-box-generic(flex-start, flex-start, column);

  .row-container {
    width: 100%;
    @include flex-box-generic(flex-start, center, row);
    gap: 2em;

    .fieldAndValidate {
      width: 100%;
      position: relative;

      p {
        color: $danger-color;
        margin-top: 1vh;
        position: absolute;
      }

      label {
        font-size: 16.8px;
        margin-bottom: 0.5em;

        span {
          color: $danger-color;
        }
      }
    }

    .button-wrapper {
      @include flex-box-generic(flex-end, flex-end, row);
      gap: 1vw;
      width: 100%;
      margin-top: 15%;
      Button {
        width: 20%;
      }
    }

    .col-container {
      width: 100%;

      @include flex-box-generic(flex-start, center, column);
      gap: 5vh;
    }
  }
}
