@import "../../../../assets/styles/abstract/mixins";
@import "../../../../assets/styles/abstract/variables";

.instructor-form-container {
  width: 70vw;
  max-width: 950px;
  height: 68vh !important;
  padding: 2vw 2vw !important;
  background-color: white;
  border-radius: 1vh;
  @include flex-box-generic(flex-start, flex-start, column);
  gap: 2vh;

  .row-container {
    width: 100%;
    @include flex-box-generic(flex-start, center, row);
    gap: 3vw;

    .fieldAndValidate {
      width: 100%;
      position: relative;

      label {
        font-size: 16.8px;
        margin-bottom: 0.5em;

        span {
          color: $danger-color;
        }
      }

      p {
        color: $danger-color;
        margin-top: 1vh;
        position: absolute;
      }

      &.gender {
        label {
          margin-bottom: 0.9em;
          line-height: 30px;
        }
      }
    }

    .button-wrapper {
      @include flex-box-generic(flex-end, flex-end, row);
      gap: 1vw;
      width: 100%;
      margin-top: 5em;

      Button {
        width: 20%;
      }
    }

    .col-container {
      width: 100%;

      @include flex-box-generic(flex-start, center, column);
      gap: 5vh !important;
    }
  }
  .name {
    gap: 2vw;
  }
}

.unit_image {
  // border: 1px solid $danger-color;
  width: 20%;
  min-width: 125px;
  max-height: 190px !important;
}
