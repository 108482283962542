@import "../../../../assets/styles/abstract/mixins";
@import "../../../../assets/styles/abstract/variables";

.add_recording{
    width: 100%;
    margin-bottom: 80px;

    .content_tab_header {
        // border: 1px solid red;
        margin-bottom: 4vh;
        width: 100%;
        h {
          margin-bottom: 0;
        }
        @include flex-box-generic(space-between, center, row);
        .buttonWrapper {
          @include flex-box-generic(flex-end, center, row);
          width: 20%;
    
          .addCourseButton {
            width: 10vw;
          }
        }
    }

    .record_form{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
}