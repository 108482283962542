@import "../../../assets/styles/abstract/mixins";
@import "../../../assets/styles/abstract/variables";

.quiz_form_container {
  height: 100%;
  .quiz_form_header {
    height: 11.5%;
    padding: 1em 1.5em;
    @include flex-box-generic(space-between, center, row);

    .quiz_form_title {
      @include flex-box-generic(center, flex-start, column);

      h6 {
        font-weight: 600;
      }

      p {
        margin-bottom: 0;
      }
    }
    border-bottom: 1px solid rgb(225, 225, 225);
  }

  .quiz_form {
    overflow: auto;
    max-width: 850px;
    height: 88.5%;
    padding: 1em 1.5em !important;
    // border-radius: 1vh;
    @include flex-box-generic(space-between, center, column);

    .row-container {
      width: 100%;
      @include flex-box-generic(flex-start, center, row);
      gap: 2vw;

      .fieldAndValidate {
        width: 100%;
        position: relative;

        label {
          font-size: 14px;
          margin-bottom: 0.5em;

          span {
            color: $danger-color;
          }
        }

        &.course {
          label {
            font-size: 14px;
            // margin-bottom: 0.8em;
            line-height: 29px;

            span {
              color: $danger-color;
            }
          }
        }

        &.premiumToggle {
          width: 30%;
          label {
            margin-bottom: 1em;
          }
          @include flex-box-generic(center, center, column);
        }

        p {
          color: $danger-color;
          margin-top: 1vh;
          position: absolute;
        }
      }

      .type {
        width: 50%;
      }

      .staff_image {
        height: 100% !important;
        width: 17%;
      }

      .button-wrapper {
        @include flex-box-generic(flex-end, flex-end, row);
        gap: 1vw;
        width: 100%;
        // margin-top: -1vh;

        Button {
          width: 50%;
        }
      }

      .col-container {
        width: 100%;

        @include flex-box-generic(flex-start, center, column);
        gap: 4.5vh;
      }
    }
  }
}

.quiz_inputs {
  padding: 0em 2em;
}
