@use "../../assets/styles/abstract/variables" as *;
@use "../../assets/styles/abstract/mixins" as *;

.login_page {
  padding: 1%;
  width: 100vw;
  height: 100vh;
  @include flex-center-row;
  background: rgb(255, 255, 255);

  .login_container {
    width: 90%;
    height: 95%;
    border-radius: 1%;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
    @include flex-box-generic(center, center, row);

    //mobile view
    @media screen and (max-width: 576px) {
      width: 100%;
      flex-wrap: wrap;
    }

    h2 {
      font-size: 3.2em;
      margin-left: -0.2em;
      color: $primary-color;
      font-family: "Inter", sans-serif;
      font-variant-numeric: tabular-nums;
      font-weight: 800;
    }

    .image_container {
      width: 30%;
      height: 100%;
      border-radius: 4%;
      min-width: 200px;
      @include flex-center-column;
      gap: 2em;
      background: rgb(255, 255, 255);

      img {
        width: 200px;
      }

      //mobile view
      @media screen and (max-width: 576px) {
        width: 100%;
        display: none;
      }
    }
  }
}

.login_container .detail_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 60%;
  height: 100%;
  border-radius: 4%;
  background-color: white;
  padding: 7em !important;

  //mobile view
  @media screen and (max-width: 576px) {
    width: 100%;
  }
}

.login_container .detail_container {
  padding: 60px 30px;
}

.title_container {
  display: flex;
  h1 {
    padding: 0px 4px;
    font-weight: 600;
  }
}

.login_container > .detail_container img {
  height: 50px;
  width: 50px;
}

.login_container .greet {
  width: 100%;
  // margin: 10px 0;
  color: $primary_color;
}
.login_container .detail_container > p {
  width: 100%;

  margin: 10px 0px;
  font-size: 1rem;
  line-height: 0.9;
  color: rgb(153, 163, 164);
}

.login_detail_container {
  @include flex-box-generic(center, flex-start, column);
  gap: 16px;
  padding: 10px 0px;
  @media screen and (max-width: 1082px) {
    flex-wrap: wrap;
  }
}
.username_container {
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  label {
    padding: 5px 0px;
  }
  input {
    padding: 6px 12px;
    border: 1px solid #e5e7e9;
    border-radius: 0.25rem;
    &:focus {
      border: 1px solid $secondary-color;
      outline: none;
    }
  }
}
.password_container {
  display: flex;
  width: 100%;

  flex-grow: 1;
  flex-direction: column;
  label {
    padding: 5px 0px;
  }
  input {
    border: 1px solid #e5e7e9;
    border-radius: 0.25rem;

    padding: 6px 12px;
    &:focus {
      border: 1px solid $secondary-color;
      outline: none;
    }
  }
}

.keep_login_container {
  margin: 10px 0px 40px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  input {
    height: 16px;
    width: 16px;
  }
  label {
    padding: 0 10px;
  }
}

.login_container .button_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 0px;
  button {
    width: 100%;
  }
}

.recover_password_button {
  font-size: 0.9rem;
}
.button_submit {
  border-radius: 32px;
  // margin-left: 1rem;
}

.terms_container {
  position: absolute;
  width: 100%;
  bottom: 0px;
  text-align: right;
  font-size: 1rem;
  line-height: 0.9;
  color: rgb(153, 163, 164);

  //mobile view
  @media screen and (max-width: 576px) {
    padding: 20px;
  }
}
