@use "../../../../assets/styles/Common/pageheader.scss";
@import "../../../../assets/styles/abstract/variables";
@import "../../../../assets/styles/abstract/mixins";

.recording_container{
    width: 100%;
    min-height: 50vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin-bottom: 80px;

    .list{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: 26px;

        .item{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            gap: 16px;
            background-color: white;
            border-radius: 8px;
            padding: 16px;

            .item-content,
            .action_buttons{
                width: 80%;
                display: flex;
                justify-content: center;
                flex-direction: column;
                gap: 16px;
                align-items: flex-start;

                h1{
                    font-size: 1.5em;
                    font-weight: bold;
                    color: $primary-color;
                }

                p{
                    font-size: 1em;
                    max-width: 80%;
                    overflow: hidden;
                }
            }

            .action_buttons{
                width: 20%;
                align-items: center;
                justify-content: space-around;
                height: 100%;

                i{
                    width: 28px;
                    height: 28px;
                    border-radius: 8px;
                    background-color: $primary-color;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: 0.3 ease-in-out;

                    svg{
                        width: 50%;
                        height: 50%;
                        color: white;
                    }

                    &:hover{
                        box-shadow: -4px -4px 11px -5px $primary-color;
                        -webkit-box-shadow: -4px -4px 11px -5px $primary-color;
                        -moz-box-shadow: -4px -4px 11px -5px $primary-color;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

@keyframes rotate {
    0%{
        rotate: 0deg;
    }
    100%{
        rotate: 360deg;
    }
}