@use "../../assets/styles/abstract/mixins" as *;
@use "../../assets/styles/abstract/variables" as *;

.richEditor {
  margin-top: 0.5em;

  .label {
    label {
      font-size: 1.2rem;
      margin-right: 0.3em;
    }
    span {
      color: $danger-color;
      font-size: 1.2rem;
    }
    margin-bottom: 1em;
  }

  .rsw-ce {
    height: 10vh;
    overflow: auto;
  }

  .rsw-ce::before {
    color: rgb(169, 169, 169) !important  ;
    font-weight: 100;
    font-size: 13px;
  }

  .rsw-editor {
    resize: vertical;
  }

  &.edit {
    .rsw-ce {
      height: 20vh;
    }
  }
}
