@use "../../../assets/styles/abstract/variables" as *;
@use "../../../assets/styles/abstract/mixins" as *;

.result_table {
  height: 100%;
  .result_table_header {
    @include flex-box-generic(space-between, center, row);
    h5 {
      margin-bottom: 0;
    }
    margin-bottom: 1em;

    .data_export {
      CsvDownload {
        height: 80px !important;
      }
    }
  }
  .result_table_container {
    height: 94%;
    position: relative;

    //react-data-table-scrollbar
    .evQegJ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    .evQegJ::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.1);
    }
    .evQegJ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.2);
    }
    .evQegJ::-webkit-scrollbar-thumb:hover {
      background: rgba($primary-color, 0.4);
    }
    .evQegJ::-webkit-scrollbar-thumb:active {
      background: rgba($primary-color, 0.4);
    }
    // }
  }
}
