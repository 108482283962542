@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@800&display=swap");

@import "../styles/abstract/mixins";
@import "../styles/abstract/variables";

html {
  font-size: 14px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: $background-color;
  color: #2b2b2b;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
  color: unset;
}

ul li {
  list-style-type: none;
}
.button {
  font-size: 1rem;
  padding: 0.4em 1.1em;
  border-radius: 4px;
  border: 1px solid #a7a8aa;
  background-color: transparent;
  word-wrap: normal;
  overflow-wrap: normal;
  white-space: nowrap;
}
.button_secondary {
  border: 1px solid $primary-color;
  width: fit-content;
  &:hover {
    background-color: $primary-color;
    color: white;
  }
}

.button_primary {
  width: fit-content;
  border: 1px solid $secondary-color;
  &:hover {
    background-color: $secondary-color;
    color: white;
  }
}

button:disabled {
  cursor: not-allowed;
}

.error {
  color: $danger-color;
}

form {
  width: 100%;
}

h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.625rem;
}
h3 {
  font-size: 1.375rem;
}
h4 {
  font-size: 1.25rem;
}
h5 {
  font-size: 1.15rem;
}
h6 {
  font-size: 1rem;
}


.arrange_dragdrop {
  display: flex;
  gap: 10px;
  align-items: center;
}

.arrange_dragdrop .arrange_button {
  display: flex;
  border: 1px solid rgb(12, 166, 12);
  padding: 0.4em 1.1em;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  align-items: center;
  color: $primary-color;
  border: 1px solid $primary-color;
  width: fit-content;
  &:hover {
    background-color: $primary-color;
    color: white;
  }

  i {
    font-size: 20px;
  }
}