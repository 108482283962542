//common scss
@use "../../assets/styles/Common/pageheader.scss";
@use "../../assets/styles/Common/table.scss";

@import "../../assets/styles/abstract/mixins";
@import "../../assets/styles/abstract/variables";

.live_table_container tr td:nth-child(1),
.live_table_container tr th:nth-child(1) {
  word-wrap: break-word;
  width: 22%;
}
.live_table_container tr td:nth-child(2),
.live_table_container tr th:nth-child(2) {
  word-wrap: break-word;
  width: 20%;
}
.live_table_container tr td:nth-child(3),
.live_table_container tr th:nth-child(3) {
  word-wrap: break-word;
  width: 20%;
}
.live_table_container tr td:nth-child(4),
.live_table_container tr th:nth-child(4) {
  word-wrap: normal;
  width: 12%;
}

.live_table_container tr td:nth-child(5),
.live_table_container tr th:nth-child(5) {
  word-wrap: normal;
  width: 12%;
}

.live_table_container tr td:nth-child(6),
.live_table_container tr th:nth-child(6) {
  word-wrap: normal;
  width: 100%;
}

.live_table_container .edit_btn {
  margin-right: 10px;
}
