@import "../../../../assets/styles/abstract/mixins";
@import "../../../../assets/styles/abstract/variables";

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
}
.content-form-container {
  width: 100%;
  max-width: 65vw;
  min-width: 65vw;
  padding: 2vw 2vw !important;
  background-color: white;
  border-radius: 1vh;
  @include flex-box-generic(flex-start, flex-start, column);
  gap: 8vh;

  .row-container {
    width: 100%;
    @include flex-box-generic(flex-start, center, row);
    gap: 1.5em;

    .file_field {
      @include flex-box-generic(flex-start, flex-start, column);
      gap: 0.5em;
      margin-right: 1em;

      .label_edit {
        width: 90%;
        display: flex;
        gap: 1em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        align-items: center;

        input {
          opacity: 0.6;
        }
      }

      .upload_container {
        width: 300px;
        @include flex-box-generic(flex-start, center, row);
        gap: 1em;
        padding: 20px;
        border-radius: 8px;
        background-color: $primary-color;

        .upload_icon {
          font-size: 18px;
          color: white;
        }
        .upload_text {
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          p {
            font-size: 14px;
            font-weight: 500;
            color: white;
            margin: 0;
          }
        }
      }

      .upload_button_container {
        width: 110px;
        @include flex-box-generic(center, center, row);
        gap: 0.75em;
        padding: 6px 15px;
        border-radius: 8px;
        background-color: $primary-color;
        flex: 1;
        cursor: pointer;

        .upload_icon {
          font-size: 14px;
          color: white;
        }

        .upload_text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          p {
            font-size: 12px;
            font-weight: 500;
            color: white;
            margin: 0;
          }
        }
      }
    }

    .video-field {
      width: 100%;
      border-radius: 8px;
      border: 1px solid $primary-color;
      padding: 1em 1.5em;
      min-height: 200px;

      @include flex-box-generic(center, flex-start, column);

      .selector-tabs {
        width: 100%;
        @include flex-box-generic(flex-start, center, row);
        gap: 1em;
        margin-bottom: 1em;

        .tab {
          font-size: 14px;
          font-weight: 500;
          color: #141415;
          border-radius: 8px;
          cursor: pointer;
          transition: all 0.2s ease-in-out;
          margin-right: 1em;
          
          &.active {
            padding: 0.5em 2em;
            border: 1px solid $primary-color;
            color: $primary-color;
            font-weight: bold;
          }
        }
      }
    }

    .fieldAndValidate {
      width: 100%;
      position: relative;

      p {
        color: $danger-color;
        margin-top: 1vh;
        position: absolute;
      }

      .preview-btn {
        position: absolute;
        right: 0;
        top: 0;
        margin-top: 1vh;
        margin-right: 1vw;
        background-color: $primary-color;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        @include flex-box-generic(center, center, row);
        color: white;

        animation: pulse 1s infinite;

        @keyframes pulse {
          0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
          }
          70% {
            transform: scale(1);
            box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
          }
          100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
          } 
        }
      }
    }

    .premium {
      label {
        font-size: 16.8px;
        margin-bottom: 0.5em;

        span {
          color: $danger-color;
        }
      }
    }

    .toggleButton {
      font-size: 1.2rem;

      label {
        span {
          color: $danger-color;
        }
      }

      width: 30%;
      @include flex-box-generic(flex-end, flex-end, row);

      .switch {
        gap: 1rem;

        @include flex-box-generic(flex-start, flex-start, column);
      }
    }

    .content_image {
      height: 100% !important;
      width: 25%;
      min-width: 120px;
    }

    .button-wrapper {
      @include flex-box-generic(flex-end, flex-end, row);
      gap: 1vw;
      width: 100%;
      margin-top: 0vh !important;

      Button {
        width: 20%;
      }
    }

    .col-container {
      width: 100%;
      @include flex-box-generic(flex-start, center, column);
      gap: 4vh;
    }
  }
}
