@import "../../../assets/styles/abstract/mixins";
@import "../../../assets/styles/abstract/variables";

.add_questions_page {
  gap: 1em;
  height: 85.5vh;

  .question_form_container {
    padding: 1em;
    width: 60%;
    height: 100%;
    margin-top: -1em;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 3px 0px,
      rgba(0, 0, 0, 0.06) 0px 0px 2px 0px;

    .add_question_form {
      height: 100%;

      .form_header {
        height: 10%;
        border-bottom: 1px solid #efefef;
        margin-bottom: 1em;
        @include flex-box-generic(space-between, center, row);

        .left_header {
          height: 100%;

          //   border: 1px solid red;
        }

        .right_header {
          height: 100%;

          //   border: 1px solid red;
        }

        .details {
          li {
            i {
              font-size: small;
              margin-right: 0.5em;
            }
            span {
              font-size: 12px;
              margin-right: 0.7em;
            }
          }
        }

        h6 {
          font-weight: 600;
        }
      }

      .form_body {
        gap: 2em;
        height: 78%;
        overflow-y: auto;
        padding: 0 1em 0 0;
        margin-bottom: 1em;
        @include flex-box-generic(space-between, space-between, column);

        label {
          margin-bottom: 1em;
          font-weight: 600;
        }

        .options_container {
          @include flex-box-generic(space-between, space-between, column);

          .options {
            gap: 1em;
            @include flex-box-generic(space-between, space-between, column);
          }
        }

        .description {
          margin-bottom: 1em;
        }
      }

      .form_body::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }
      .form_body::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.1);
      }
      .form_body::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.2);
      }
      .form_body::-webkit-scrollbar-thumb:hover {
        background: rgba($primary-color, 0.4);
      }
      .form_body::-webkit-scrollbar-thumb:active {
        background: rgba($primary-color, 0.4);
      }

      .form_action {
        @include flex-box-generic(flex-end, flex-end, row);
        height: 7%;
        .double_button {
          gap: 0.5em;
          width: 100%;
          @include flex-box-generic(flex-end, flex-end, row);
        }
      }
    }
  }

  .navigator {
    width: 40%;
    height: 100%;
    margin-top: -1em;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 3px 0px,
      rgba(0, 0, 0, 0.06) 0px 0px 2px 0px;

    padding: 1em;

    .quiz_navigator {
      height: 100%;
      @include flex-box-generic(flex-start, space-between, column);

      .navigator_header {
        height: 10%;
        @include flex-box-generic(space-between, flex-start, row);

        h6 {
          font-weight: 600;
        }

        p {
          margin-bottom: 0;
        }
      }

      .question_link_list {
        width: 100%;
        max-height: 100%;
        display: flex;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
          rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        padding: 1em;
        flex-wrap: wrap;
        gap: 0.4em;

        overflow-y: auto;

        .question_link {
          aspect-ratio: 1 / 1;
          width: 2em;
          height: 2em;
          border-radius: 50%;
          @include flex-box-generic(center, center, column);
          background-color: rgb(225, 225, 225);
          cursor: pointer;

          p {
            font-size: x-small;
            margin: 0;
          }

          &.done {
            background-color: rgb(154, 221, 156);
          }

          &.active {
            background-color: orange;
          }
        }
      }

      .question_link_list::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }
      .question_link_list::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.1);
      }
      .question_link_list::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.2);
      }
      .question_link_list::-webkit-scrollbar-thumb:hover {
        background: rgba($primary-color, 0.4);
      }
      .question_link_list::-webkit-scrollbar-thumb:active {
        background: rgba($primary-color, 0.4);
      }
    }
  }
}
