@import "../../../assets//styles/abstract/mixins";
@import "../../../assets/styles/abstract/variables";

.dashboard {
 display: flex;
 justify-content: center;

  .dashboard_container{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: stretch;
    padding-bottom: 2rem;
    gap: 1rem;

  .top_container{
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1rem;

    .count_container{
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;

      .label_card{
        padding-top: 2rem;
        display: flex;
        flex: 1;
        flex-direction: column;
        background-color: white;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        gap: 0.5rem;


        .icon{
          text-align: center;
          font-size: medium;
        }

        .text-container{

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          h6{
            font-size: medium;
            font-weight: bold;
            margin-bottom: 0px;
          }
  
          p{
            font-size: small;
  
          }
        }

       
      }
    }

    .antdstat_container{
      background-color: white;
      border-radius: 10px;
      padding: 1rem;
    }

    .chart_containers{
      display: flex;
      gap: 1rem;

      .barChart_container{
        width: 60%;
        background-color: white;
        padding: 1rem;
        border-radius: 10px;
        align-items: center;
      }

      .payment_container{
        width: 40%;
        padding: 1rem;
        background-color: white;
        border-radius: 10px;
        align-items: center;
      }
    }

  }

  .middle_chart_container{
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1rem;
    flex: 1;
    

    
    .left_chart_container{
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 1rem;

      .barChart_container{
        background-color: white;
        padding: 1rem;
        border-radius: 10px;
        height:370px
      }

      .recent_container{
        display: flex;
        flex-direction: column;
        gap:1rem;
  
        .progress_bar_container{
          background-color: white;
          padding: 1rem;
          border-radius: 10px;
        }
        
        .antdtable_container{
          background-color: white;
          padding-bottom: 1rem;
          border-radius: 10px;
        }
  
      }
    }

    .enrollment_chart_bar{
      background-color: white;
      padding: 1rem;
      border-radius: 10px;
    }

  }

  .bottom_container{
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1rem;

    .user_trend_container{
      background-color: white;
      padding: 1rem;
      border-radius: 10px;
      height: 300px;
    }

    .payment_container{
      padding: 1rem;
      background-color: white;
      border-radius: 10px;
      height: 300px;
    }

  }

}
}

.barChart_container {
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 10px;
  align-items: center;
}


.count_container.testPayment{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 1rem;

  .label_card{
    padding-top: 2rem;
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: white;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    gap: 0.5rem;


    .icon{
      text-align: center;
      font-size: medium;
    }

    .text-container{

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h6{
        font-size: medium;
        font-weight: bold;
        margin-bottom: 0px;
      }

      p{
        font-size: small;

      }
    }

   
  }
}