.page_header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
  @media screen and (max-width: 478px) {
    margin: 0;
    margin-bottom: 24px;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
  }
  .page_title {
    margin-right: auto;
    margin-bottom: 0;
    @media screen and (max-width: 478px) {
      width: 100%;
      align-self: flex-start;
    }
  }

  .search_wrapper {
    width: 100%;
    max-width: 275px;
    min-width: 120px;
    display: block;
    margin: auto;
    margin-right: 3rem;
    margin-left: 2rem;
    @media screen and (max-width: 768px) {
      margin-right: 0.5rem;
    }
    @media screen and (max-width: 478px) {
      flex: 1;
      margin: 0;
      max-width: 178px;
      width: 50%;
      margin: 0.2rem 0;
      margin-right: 0.6rem;
    }
  }

  .button_wrapper {
    width: 125px;
    min-width: fit-content;
    @media screen and (max-width: 478px) {
      margin: 0;
      max-width: 92px;
      width: 40%;
      margin: 0.2rem 0;
    }
  }
}
