.profile_page {
  h6 {
    color: #da2f2f;
  }
  .profile_icons {
    width: 60%;
    margin: auto;
    padding: 0.75em 1em;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 15px;
    gap: 1.5em;
    border: 1px solid white;
    margin-top: 1.5em;
    i {
      color: white;
    }
  }
}
