.tag {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 9px 7px;
  font-size: x-small;
  border-radius: 10px;

  &.red {
    color: red;
    border: 1px solid red;
    background-color: rgba($color: red, $alpha: 0.1);
  }
  &.green {
    color: green;
    border: 1px solid green;
    background-color: rgba($color: green, $alpha: 0.1);
  }
  &.orange {
    color: orange;
    border: 1px solid orange;
    background-color: rgba($color: orange, $alpha: 0.1);
  }
  &.black {
    color: black;
    border: 1px solid black;
    background-color: rgba($color: black, $alpha: 0.1);
  }
  &.grey {
    color: grey;
    border: 1px solid grey;
    background-color: rgba($color: grey, $alpha: 0.1);
  }
  &.purple {
    color: purple;
    border: 1px solid purple;
    background-color: rgba($color: purple, $alpha: 0.1);
  }
  &.small {
    font-size: small;
  }
}
