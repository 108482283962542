@use "../../assets/styles/abstract/variables" as *;
@use "../../assets/styles/abstract/mixins" as *;

.test_page {
  height: 86vh;
  margin-top: -15px !important;
  margin-right: -20px !important;

  .schedule_container {
    height: 100%;
  }
  .result_container {
    height: 100%;
    margin: 0;
    @include flex-box-generic(space-between, space-between, column);

    .result_header {
      padding: 0.6em 1em;
      height: 14.25%;
      border-radius: 4px;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 3px 0px,
        rgba(0, 0, 0, 0.06) 0px 0px 2px 0px;

      .result_header_contents {
        height: 100%;
        @include flex-box-generic(space-between, center, row);

        .test_name {
          gap: 0.9em;
          @include flex-box-generic(center, flex-start, column);

          h5 {
            font-weight: 600;
          }
        }
        .status_tag {
          min-width: 80px;
          min-height: 65px;
        }
      }
    }
    .result_body {
      height: 84.25%;
      padding: 0.6em 1em;
      border-radius: 4px;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 3px 0px,
        rgba(0, 0, 0, 0.06) 0px 0px 2px 0px;
    }

    .add_quiz_form {
      height: 100%;
      border-radius: 4px;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 3px 0px,
        rgba(0, 0, 0, 0.06) 0px 0px 2px 0px;
    }
  }
}

.add_import_questions {
  width: 30%;
  gap: 0.5em;
  @include flex-box-generic(center, center, column);

  label {
    margin-bottom: 0;
    font-weight: 600;
  }

  .buttons {
    width: 100%;

    gap: 0.5em;

    @include flex-box-generic(center, center, row);
    .add,
    .import {
      width: 50%;
    }
  }
}
