@use "../../../assets/styles/abstract/variables" as *;
@use "../../../assets/styles/abstract/mixins" as *;

.test_container {
  box-sizing: border-box;
  height: 100% !important;
  border-radius: 4px;

  @include flex-box-generic(space-between, center, column);

  padding: 1em;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 2px 0px;

  .add_quiz_button {
    width: 100%;

    margin-bottom: 1em;
  }

  .all_tests_header {
    width: 100%;

    @include flex-box-generic(space-between, center, row);

    h6 {
      margin-bottom: 0;
    }

    .test_filters {
      .custom-select {
        font-size: 12px;
        min-width: 100px;
        border-radius: 5px;
        border: 1px solid rgba($secondary_color, 0.2);
        margin-left: 1em;

        option {
          font-size: 12px;
        }

        label {
          font-size: x-small;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }

  .tests_list {
    width: 100%;
    height: 62.5vh;
    overflow-y: scroll;
    margin-bottom: 1.5em;
    padding-right: 1em;
  }
  .tests_list::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  .tests_list::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
  }
  .tests_list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
  }
  .tests_list::-webkit-scrollbar-thumb:hover {
    background: rgba($primary-color, 0.4);
  }
  .tests_list::-webkit-scrollbar-thumb:active {
    background: rgba($primary-color, 0.4);
  }
}

.singleQuiz {
  border: 1px solid rgba(255, 0, 0, 0);
  border-radius: 10px;

  background-color: rgb(248, 249, 250);
  cursor: pointer;
  .quiz_header {
    padding-bottom: 0.5em;
    @include flex-box-generic(space-between, center, row);

    .quiz_name {
      font-size: 14px;
      font-weight: 600;
      color: rgba($secondary_color, 0.5);
      margin-bottom: 0;
    }

    .status_tag {
      min-width: 66px;
    }
  }

  .description {
    li {
      i {
        font-size: x-small;
        margin-right: 0.5em;
      }
      span {
        font-size: 10px;
        margin-right: 1em;
      }
    }
  }

  .dates {
    small {
      font-size: 11px;

      span {
        font-weight: 600;
      }
    }
  }

  .action_buttons {
    @include flex-box-generic(space-between, flex-end, row);
    min-width: 100px;

    i {
      font-size: 13px;
      color: rgba($secondary_color, 0.6);
    }
  }

  &.active {
    border: 1px solid $primary-color;
    background-color: rgba($primary-color, 0.1);
  }
}
